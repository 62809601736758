.pbiEmbeddWrapper
  background-color: white
  &__pbiEmbedd
    background-color: white
    padding: $paddingBig
    height: 1250px
    position: relative
    &__fullscreenClose
      position: absolute
      top: 1px
      right: 1px
      font-weight: 900
      font-size: 2em
      z-index: 9999999999
      padding: 1em
      cursor: pointer
    &__libraryLink
      display: flex
      background-color: white
      color: $colorLink
      margin: $paddingBig 0
      align-items: center
      gap: .5em
      svg
        width: .7em
        height: .7em
        cursor: pointer
      h4
        cursor: pointer
    &__info
      padding: $paddingBig
      &__content
        &__click
          cursor: pointer
          color: #6262f0
    iframe
      border: none
    .embed
      height: 100%
      margin-top: $paddingBig
    &__orgnr
      margin: $paddingBig
      background-color: white
      &__input
        margin-top: $paddingBig
        display: flex
        flex-direction: row
          
.pbiReportsWrapper
  background-color: #f1f1ff
  &__pbiEmbedd
    background-color: #f1f1ff
    padding: $paddingBig
    height: 1250px
    &__reportsLibrary
      background-color: #f1f1ff
      display: flex
      flex-wrap: wrap
      &__item
        display: grid
        margin: 1em
        min-height: 300px
        max-width: 250px
        min-width: 250px
        padding: 1em
        background-color: white
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px
        color: $colorLink
        &:hover
          box-shadow: rgba(149, 157, 165, 0.2) 0px 16px 48px
          cursor: pointer
        &__icon
          align-self: center
          justify-self: center
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px
          background-color: transparent
          &__img
            display: block
        &__description
          margin-left: 1em
          margin-right: 1em
          span
            color: #9b99b1
            font-style: italic
            font-size: .9em
